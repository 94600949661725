<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default: {}
      }
    },
    data() {
      return {
        showWriteComment: false,
        sending: false,
        firstIn: true,

      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      noRouter() {
        return this.config.noRouter
      },
      inn() {
        return this.publicData.inn || {}
      },
      tour() {
        return this.publicData.tour || {}
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
    },
    watch: {
      tour(val, oldval) {
        if (val == oldval) {
          return
        }
        console.log(val, oldval, val == oldval)
        this.updateTour(val)
      },
      senceGuid(val, oldval) {
        if (val == oldval) {
          return
        }
        this.updateSence(val)
      }
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          getgroup: this.getgroup,
          getPano: this.getPano,
          toPano: this.toPano,
          toGroup: this.toGroup,
          setFirstIn: this.setFirstIn,

        }
      })
      this.updateTour()
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      setFirstIn(b) {
        this.firstIn = b
      },
      getgroup(groupName, tour) {
        var g = []
        var t = tour || this.tour
        for (var i in t.items) {
          var item = t.items[i]
          if (item.roomId == groupName) {
            g.push(item)
          }
        }
        return g
      },
      getPano(senceGuid) {
        var s = senceGuid || this.senceGuid
        for (var i in this.tour.items) {
          if (this.tour.items[i].guid == s) {
            return this.tour.items[i]
          }
        }
      },
      toPano(guid, obj) {
        if (this.noRouter) {
          this.getFunc('changePublicData')({ senceGuid: guid })
        } else {
          var rd = {}
          for (var i in this.routerDatas) {
            rd[this.routerDatas[i]] = this.$route.query[this.routerDatas[i]]
          }
          this.$router.push({ query: { senceId: guid, from: this.$route.query.from, ...rd, ...obj } })
        }
      },
      toGroup(guid, obj) {
        if (this.noRouter) {
          this.getFunc('changePublicData')({ senceGuid: guid })
        } else {
          var rd = {}
          for (var i in this.routerDatas) {
            rd[this.routerDatas[i]] = this.$route.query[this.routerDatas[i]]
          }
          this.$router.push({ query: { groupId: guid, from: this.$route.query.from, ...rd, ...obj } })
        }
      },
      updateSence(sence) {
        var s = sence || this.senceGuid
        this.update(this.tour, s)
      },
      updateTour(tour) {
        var t = tour || this.tour
        console.log(t, this.senceGuid)
        this.update(t, this.senceGuid)
      },
      update(tour, senceGuid) {
        console.log(tour, senceGuid)
        var pano
        if (!tour) {
          return
        }
        if (!tour.items) {
          return
        }
        var si = 0
        for (var i in tour.items) {
          if (tour.items[i].guid == senceGuid) {
            si = i
          }
        }
        pano = this.itemToPano(tour, si, this.inn)
        this.getFunc('changePublicData')({ pano: pano, senceGuid: tour.items[si].guid, guid: tour.guid, groupName: tour.items[si].roomId, groupItems: this.getgroup(tour.items[si].roomId, tour) })
      },
      itemToPano(tour, index, inn) {
        if (index >= tour.items.length || index < 0) {
          index = 0
        }
        var item = tour.items[index]
        var pano = item.linkItem
        pano.audio = item.audio
        pano.audioAutoPlay = item.audioAutoPlay
        pano.audioLoop = item.audioLoop
        pano.hotspots = item.hotspots
        pano.title = `${item.title}`//`${tour.title} - ${item.title}`
        pano.description = item.description || tour.description || inn.description
        pano.file = item.file
        if (inn.isPro) {
          pano.links = tour.links || []
        } else {
          pano.links = []
        }
        pano.gpsLat = inn.lat
        pano.gpsLng = inn.lng
        pano.fov = item.fov
        pano.hLookAt = item.hLookAt
        pano.vLookAt = item.vLookAt
        if (this.firstIn) {
          pano.littlePlanetIntro = tour.littlePlanetIntro
          this.firstIn = false
        } else {
          pano.littlePlanetIntro = false
        }
        pano.blend = tour.blend
        pano.weather = item.weather
        pano.postprocessing = item.postprocessing || pano.postprocessing
        pano.logo = tour.logo
        pano.hideLogo = tour.hideLogo
        pano.hideCount = tour.hideCount
        pano.paid = inn.paid
        pano.user = inn.user || { userName: '' }
        pano.isPro = inn.paid
        pano.praiseCount = tour.praiseCount
        pano.viewCount = tour.viewCount
        pano.shareCount = tour.shareCount
        return pano
      }
    },
  }
</script>
<style scoped>
</style>
